import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux';
import '../css/annotation-properties.css'
import '../css/modal.css'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import { GroupPermissionsInProjectList, UserPermissionsInProjectList } from './PermissionList';
import { selectOrganisationsInfo } from '../App/organisationsInfoSlice';
import { HasUploadLicenseInOrganisation, CanUpdateProject } from '../App/UserPermissions'
import { ToastError, ToastSuccess } from '../Components/ToastDisplay'
import { ShareProjectWithExternalUser } from '../Api/Sharing'
import { ExternalUserPermissions } from './EditModelPermissions';

import { BeaconDialogMap, BeaconSetSuggestions } from '../Components/BeaconDialogMap';

import { Logger } from 'aws-amplify';
const logger = new Logger('EditProject');


export function EditProject(props) {

    const { project, show, setShow, onCreateProject, onUpdateProjectTitle, dialogTitle, isAdding, helpContext, parentHelpContext, currentUser } = props;

    logger.debug("EditProject props", props);

    // const orgsInfo = useSelector(selectOrganisationsInfo);

    const form = useRef(null);
    const toastid = useRef(null);

    const [title, setTitle] = useState();
    const [userPermissions, setUserPermissions] = useState();
    const [groupPermissions, setGroupPermissions] = useState();

    const [validated, setValidated] = useState(false);
    const [uiDisabled, setUiDisabled] = useState(false);
    const [showAddExternalUser, setShowAddExternalUser] = useState(false);

    const orgsInfo = useSelector(selectOrganisationsInfo);

    React.useEffect(() => {
        if (show) {
            BeaconSetSuggestions(helpContext);
        }
        else {
            BeaconSetSuggestions(parentHelpContext);
        }
    }, [helpContext, parentHelpContext, show])


    React.useEffect(() => {

        if (show) {
            setTitle(project?.Title);
            setUserPermissions(project.UserPermissions);
            setGroupPermissions(project.GroupPermissions);
        }
    }, [project, show])


    function onSaveButton(event) {
        event.preventDefault();

        if (form.current.checkValidity()) {

            //copy project
            let newProject = JSON.parse(JSON.stringify(project));

            //update with form values
            newProject.Title = title;
            newProject.UserPermissions = userPermissions;
            newProject.GroupPermissions = groupPermissions;

            if (isAdding) {
                onCreateProject(newProject, setUiDisabled);
            }
            else {
                onUpdateProjectTitle(newProject, setUiDisabled);
            }

        }
        else {
            //failed validation
            event.stopPropagation();

        }

        setValidated(true);
    }

    function onClose() {
        setShow(false);
    }

    async function onAddExternalUser(permissions) {

        try {
            await ShareProjectWithExternalUser(permissions.UserName, permissions.Permissions, project.ProjectId, permissions.Propagate);
            ToastSuccess("A share to this user has been added.\nYou may now select them in any user lists when setting permissions.", toastid.current, 50000);
            setShowAddExternalUser(false);
        } catch (error) {
            ToastError(error.message, error.cause, toastid.current);
        }
    }

    return (
        <Modal
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
            dialogClassName="modal-900w"
        >
            <Modal.Header closeButton>
                <Modal.Title>{dialogTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="mx-2 my-2">
                    <Row className="border-bottom-only">
                        <Form ref={form} noValidate validated={validated} onSubmit={onClose}>
                            <Form.Group className="my-2" controlId="formProjectTitle" as={Row}>
                                <Form.Label column sm={2} >Title:</Form.Label>
                                <Col sm={8}>
                                    <Form.Control required value={title} onChange={(e) => setTitle(e.target.value)} placeholder='Project Title...' 
                                        disabled={!CanUpdateProject(currentUser, project?.ProjectId, project?.OrganisationId) && !isAdding}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a title.
                                    </Form.Control.Feedback>
                                </Col>
                                {(!isAdding && CanUpdateProject(currentUser, project?.ProjectId, project?.OrganisationId)) &&
                                    <Col sm={2}>
                                        <Button disabled={uiDisabled} variant="primary" onClick={onSaveButton}>Save</Button>
                                    </Col>
                                }
                            </Form.Group>
                        </Form>
                    </Row>
                    <Row className="mt-5">
                        <Col>
                            <UserPermissionsInProjectList
                                parentHelpContext={helpContext}
                                userPermissions={userPermissions}
                                setUserPermissions={setUserPermissions}
                                canEdit={true}
                                userOrg={orgsInfo?.organisations?.find(o => o?.OrganisationId === orgsInfo.currentUser?.OrganisationId)}
                                orgs={orgsInfo?.organisations}
                                projectId={project?.ProjectId}
                                isAdding={isAdding}
                                showUploadPermission={HasUploadLicenseInOrganisation(orgsInfo.currentUser, orgsInfo?.currentOrgId)}
                            />
                        </Col>
                        <Col>
                            <GroupPermissionsInProjectList
                                parentHelpContext={helpContext}
                                groupPermissions={groupPermissions}
                                setGroupPermissions={setGroupPermissions}
                                canEdit={true}
                                userOrg={orgsInfo?.organisations?.find(o => o?.OrganisationId === orgsInfo.currentUser?.OrganisationId)}
                                project={project}
                                isAdding={isAdding}
                                orgs={orgsInfo?.organisations}
                                showUploadPermission={HasUploadLicenseInOrganisation(orgsInfo.currentUser, orgsInfo?.currentOrgId)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button className="list-top-button" onClick={() => setShowAddExternalUser(true)}>Share with external user</Button>
                            <ExternalUserPermissions
                                helpContext={BeaconDialogMap.ShareWithAnExternalUserDialog} parentHelpContext={helpContext}
                                showpropagate={true}
                                showDialog={showAddExternalUser}
                                setShowDialog={setShowAddExternalUser}
                                onSavePermission={onAddExternalUser}
                                subItemTitle="Models in the Project"
                            />
                        </Col>
                    </Row>

                </Container>
            </Modal.Body>
            <Modal.Footer>
                {isAdding ? <>
                    <Button disabled={uiDisabled} variant="primary" onClick={onSaveButton}>OK</Button>
                    <Button disabled={uiDisabled} variant="secondary" onClick={onClose}>Cancel</Button>
                </> :
                    <Button variant="primary" onClick={onClose}>Close</Button>}
            </Modal.Footer>
        </Modal>

    );

}

export default EditProject;