import React, { useState, useRef } from "react";
import Button from 'react-bootstrap/Button'
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { CanEditLicenses, CanAssignLicenses } from "../App/UserPermissions";
import { availableLicenseTypeOptionsUser, LicenseTypes } from '../App/Permissions'

import { ToastLoading, ToastError, ToastSuccess } from './ToastDisplay'
import { CreateLicense, UpdateLicense, DeleteLicense, AssignLicenseToUser, UnassignLicenseFromUser } from '../Api/License'
import { AddLicense } from "./AddLicense";
import { EditLicense } from "./EditLicense";
import { ListSelector } from "./ListSelector";
import { Confirm } from 'react-st-modal';
import { fullUserTitle, fullUsersName } from '../App/Utils'
import '../css/lists.css'
import { UserStatus } from '../App/Permissions'
import { BeaconDialogMap } from '../Components/BeaconDialogMap';
import { Logger } from 'aws-amplify';
const logger = new Logger('LicenseListOrg');

export function UserLicenseList(props) {
    logger.debug("props", props);
    const { currentUser, currentOrg, orgs, parentHelpContext } = props;

    logger.debug(props);

    const [selectedLicenseId, setSelectedLicenseId] = useState(null);
    const [showAddLicense, setShowAddLicense] = useState(false);
    const [showEditLicense, setShowEditLicense] = useState(false);
    const [showAssignLicense, setShowAssignLicense] = useState(false);
    const [uiDisabled, setUiDisabled] = useState(false);
    const userLicenses = currentOrg?.Licenses.filter(l => l.Type === LicenseTypes.ReadAccess || l.Type === LicenseTypes.WriteAccess);


    React.useEffect(() => {
        licenseList?.current?.resetAfterIndex(0);
    }, [currentOrg?.Licenses])

    const licenseList = useRef();
    const toastId = useRef(null);

    function onAddLicenseButton() {
        setShowAddLicense(true);
    }

    function onEditLicenseButton() {
        setShowEditLicense(true);
    }

    const selectedLicense = userLicenses?.find(l => l.LicenseId === selectedLicenseId);

    function assignLicense(userId, setUiDisabled) {

        setUiDisabled(true);

        AssignLicenseToUser(selectedLicenseId, userId)
            .then(res => {
                ToastSuccess("License assigned", toastId.current, 5000);
                setUiDisabled(false);
                setShowAssignLicense(false);
            })
            .catch(err => {
                ToastError(err.message, err.cause, toastId.current);
                setUiDisabled(false);

            });
    }

    function onUnAssignLicenseButton() {


        setUiDisabled(true);
        if (selectedLicense?.UserId) {
            UnassignLicenseFromUser(selectedLicenseId, selectedLicense.UserId)
                .then(res => {
                    ToastSuccess("License un-assigned", toastId.current, 5000);
                    setUiDisabled(false);

                    setShowAddLicense(false);
                })
                .catch(err => {
                    ToastError(err.message, err.cause, toastId.current);
                    setUiDisabled(false);

                });
        }
    }

    function onAssignLicenseButton() {
        setShowAssignLicense(true);
    }


    function createLicense(license) {
        CreateLicense(license)
            .then(res => {
                ToastSuccess("License Added", toastId.current, 5000);
                setShowAddLicense(false);
            })
            .catch(err => {
                ToastError(err.message, err.cause, toastId.current);
            });

    }

    function editLicense(license, setUiDisabled) {
        setUiDisabled(true);
        UpdateLicense(license)
            .then(res => {
                ToastSuccess("License Updated", toastId.current, 5000);
                setUiDisabled(false);
                setShowEditLicense(false);
            })
            .catch(err => {
                setUiDisabled(false);
                ToastError(err.message, err.cause, toastId.current);
            });

    }

    async function onDeleteLicenseButton() {
        const result = await Confirm("Are you sure you want to delete License?", "Delete License");
        if (result) {
            toastId.current = ToastLoading("Deleting License");

            DeleteLicense(selectedLicenseId)
                .then(res => {
                    ToastSuccess("License Deleted", toastId.current, 5000);
                    setSelectedLicenseId(null);
                })
                .catch(err => {
                    ToastError(err.message, err.cause, toastId.current);
                });
        }
    }

    function itemClassName(license, selected) {
        if (selected) {
            return "list-item list-item-selected";
        }
        else if (!license.IsActive) {
            return "list-item list-item-warning";
        }
        else {
            if (license.UserId === "00000000-0000-0000-0000-000000000000") {
                return "list-item list-item-highlighted";
            }
            else {
                return "list-item list-item-not-selected";
            }
        }
    }

    const ListRowLicense = ({ index, style }) => (

        <div role="button"
            style={{
                ...style,
                backgroundColor: "#FAFAFA",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflowX: "hidden"
            }}
            className={itemClassName(userLicenses[index], selectedLicenseId === userLicenses[index].LicenseId)}
            onClick={() => {
                //set group and prompt re-draw
                setSelectedLicenseId(userLicenses[index].LicenseId);
            }}
        >

            {userLicenses[index].IsActive ?
                <div className="list-item-title">
                    <div>{userLicenses[index].Title}</div>
                </div>
                :
                <div className="list-item-split-row-spaced">
                    <div className="list-item-title list-item-split-row-item-left">
                        <div>{userLicenses[index].Title}</div>
                    </div>
                    <div className="list-item-title list-item-split-row-item-right">INACTIVE</div>
                </div>
            }

            {(!userLicenses[index].IsActive && userLicenses[index].StatusText) &&
                <div className="list-item-normal-item">
                    {"Status: " + userLicenses[index].StatusText}
                </div>}

            {(userLicenses[index].UserId !== "00000000-0000-0000-0000-000000000000") ?
                <div className="list-item-sub-item">
                    {"Assigned to: " + fullUserTitle(userLicenses[index].UserId, orgs, currentOrg)}
                </div>
                :
                <div className="list-item-sub-item">
                    {"Not Assigned"}
                </div>

            }
        </div>
    );

    function getRowHeight(index) {
        if (userLicenses[index]?.IsActive) {
            return 60;
        }
        else {
            return 80;
        }
    }

    function userList() {
        //users in this org
        let list = currentOrg?.Users.filter(u => u.Status === UserStatus.Active).map(u => ({ id: u.UserId, label: fullUsersName(u, null, false) }));
        //users shared into this org
        currentOrg?.UsersSharedIn?.map(u => ({ id: u.UserId, label: u.OrgSharedFromTitle + " : " + u?.UserName }))?.forEach(u => list.push(u));

        return list;
    }


    return (
        currentUser != null ?
            <div className="list-title-and-list list-short  list-narrow">
                <div className="list-title-and-buttons">
                    <div className="list-title" >User Licenses:</div>
                    <div className="list-buttons">
                        {(CanEditLicenses(currentUser)) && <Button className="list-top-button" onClick={onAddLicenseButton}>Add</Button>}
                        {(CanEditLicenses(currentUser)) && <Button disabled={selectedLicenseId === null} className="list-top-button" onClick={onEditLicenseButton}>Edit</Button>}
                        {(CanEditLicenses(currentUser)) &&
                            <Button
                                disabled={selectedLicenseId === null}
                                className="list-top-button"
                                onClick={onDeleteLicenseButton}>
                                Delete
                            </Button>
                        }
                        {(CanAssignLicenses(currentUser, currentOrg.OrganisationId)) &&
                            <Button
                                disabled={selectedLicenseId === null || selectedLicense?.UserId !== "00000000-0000-0000-0000-000000000000"}
                                className="list-top-button"
                                onClick={onAssignLicenseButton}>
                                Assign User
                            </Button>
                        }
                        {(CanAssignLicenses(currentUser, currentOrg.OrganisationId)) &&
                            <Button disabled={selectedLicenseId === null || selectedLicense?.UserId === "00000000-0000-0000-0000-000000000000" || uiDisabled}
                                className="list-top-button"
                                onClick={onUnAssignLicenseButton}>
                                Un-Assign
                            </Button>
                        }
                    </div>
                </div>

                <div className="list-container">
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                ref={licenseList}
                                height={height}
                                itemCount={userLicenses ? userLicenses.length : 0}
                                itemSize={getRowHeight}
                                width={340}
                                className="list-background-style"
                            >
                                {ListRowLicense}
                            </List>
                        )}
                    </AutoSizer>
                </div>
                <AddLicense
                    helpContext={BeaconDialogMap.AddUserLicenseDialog}
                    parentHelpContext={parentHelpContext}
                    availableLicenseTypes={availableLicenseTypeOptionsUser()}
                    show={showAddLicense}
                    setShow={setShowAddLicense}
                    createLicense={createLicense}
                    currentOrg={currentOrg}
                    orgs={orgs}

                />
                <EditLicense
                    helpContext={BeaconDialogMap.EditLicenseDialog}
                    parentHelpContext={parentHelpContext}
                    show={showEditLicense}
                    setShow={setShowEditLicense}
                    editLicense={editLicense}
                    licenseToEdit={selectedLicense}
                />
                <ListSelector
                    helpContext={BeaconDialogMap.AssignUserToLicenseDialog}
                    parentHelpContext={parentHelpContext}
                    itemName={"User"}
                    title={"Select User"}
                    show={showAssignLicense}
                    setShow={setShowAssignLicense}
                    onSaveItem={assignLicense}
                    items={userList()}

                />

            </div>
            :
            <></>
    );

}