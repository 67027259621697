import React, { useState, useRef } from 'react';
import Select, { components } from 'react-select'
import { EditAnnotationSet } from './EditAnnotationSet';
import { Logger } from 'aws-amplify';
import '../css/lists.css'
import Button from 'react-bootstrap/Button'
import { v4 as uuid } from "uuid";
import { ToastError, ToastLoading, ToastSuccess } from '../Components/ToastDisplay'
import { CreateAnnotationSet, UpdateAnnotationSet, DeleteAnnotationSet } from '../Api/AnnotationApi'
import { ConfirmModal } from '../Components/ConfirmModal'
import { selectStyles } from '../App/Utils'
import { BsLockFill } from "react-icons/bs";
import { CopyAnnotationSet } from '../Components/CopyAnnotationSet';
import { CanCreateAnnotationSet, CanEditModelPermissions } from '../App/UserPermissions';
import Spinner from 'react-bootstrap/Spinner'
import { BeaconDialogMap } from '../Components/BeaconDialogMap';

const logger = new Logger('AnnotationSetSelect');


//=======================================

export function AnnotationSetSelect(props) {
    logger.debug(props);
    const { project, model, selectedAnnotationSetId, setSelectedAnnotationSetId, orgsInfo, loadingAnnotationSetContents, parentHelpContext } = props;
    const [availableAnnotationSetOptions, setAvailableAnnotationSetOptions] = useState(model?.AnnotationSets ? SetsToOptions(model.AnnotationSets) : []);
    const [showNewAnnotatonSetModal, setShowNewAnnotatonSetModal] = useState(false);
    const [showEditAnnotatonSetModal, setShowEditAnnotatonSetModal] = useState(false);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
    const [showCopyAnnotatonSetModal, setShowCopyAnnotatonSetModal] = useState(false);
    const toastId = useRef();

    function setToOption(annotationSet) {
        return {
            value: annotationSet?.AnnotationSetId,
            label: annotationSet?.Title,
            lastChanged: "Created: " + (new Date(annotationSet?.DateCreated)).toLocaleString(),
            isLocked: annotationSet?.IsLocked
        };
    }

    function SetsToOptions(sets) {
        return sets?.map(as => (setToOption(as)));
    }


    //look for model change
    React.useEffect(() => {
        if (model?.ModelId) {
            setAvailableAnnotationSetOptions(SetsToOptions(model?.AnnotationSets));
            if (!model?.AnnotationSets?.find(as => as.AnnotationSetId === selectedAnnotationSetId)) {
                if (model?.AnnotationSets?.length) {
                    setSelectedAnnotationSetId(model?.AnnotationSets[0].AnnotationSetId);
                }
            }
        }
        else {
            setAvailableAnnotationSetOptions([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [model, model?.AnnotationSets, selectedAnnotationSetId, setSelectedAnnotationSetId])

    function setSelected(selectedOption) {

        setSelectedAnnotationSetId(selectedOption.value)
    }

    function createAnnotationSet(newAnnotationSet, setUiDisabled) {

        toastId.current = ToastLoading("Adding Annotation Set");

        setUiDisabled(true);

        CreateAnnotationSet(newAnnotationSet)
            .then(res => {
                ToastSuccess("Added", toastId.current, 2000);
                setShowNewAnnotatonSetModal(false);
                setUiDisabled(false);
            })
            .catch(err => {
                ToastError(err.message, err.cause, toastId.current);
                setUiDisabled(false);
            });

    }

    function editAnnotationSet(newAnnotationSet, setUiDisabled) {
        toastId.current = ToastLoading("Updating Annotation Set");

        setUiDisabled(true);

        UpdateAnnotationSet(newAnnotationSet)
            .then(res => {
                ToastSuccess("Updated", toastId.current, 2000);
                setUiDisabled(false);
            })
            .catch(err => {
                ToastError(err.message, err.cause, toastId.current);
                setUiDisabled(false);
            });

    }

    function onDelete() {
        setShowConfirmDeleteDialog(true);
    }

    function onDeleteConfirmed() {
        toastId.current = ToastLoading("Deleting Annotation Set");

        DeleteAnnotationSet(selectedAnnotationSetId)
            .then(res => {
                ToastSuccess("Deleted", toastId.current, 2000);
            })
            .catch(err => {
                ToastError(err.message, err.cause, toastId.current);
            });

    }

    function SingleValue({ children, ...props }) {

        const val = props.getValue();

        return (
            <components.SingleValue {...props}>
                <div className="list-item-split-row-spaced">
                    {children}

                    {val[0]?.isLocked && <BsLockFill />}
                </div>
            </components.SingleValue>
        );
    };

    function formatOptionLabel(option, meta) {

        const { value, label, lastChanged, isLocked } = option;
        const { context } = meta;

        if (value === null || value === undefined) {
            return <div className="text-muted">No Annotation Sets</div>
        }
        else if (context === "menu") {
            return (
                <div role="button"
                    className={value === selectedAnnotationSetId ?
                        "select-list-item select-list-item-selected" :
                        "select-list-item select-list-item-not-selected"}

                >
                    <div className="list-item-split-row-spaced">
                        <div className="list-item-title">{label}</div>
                        {isLocked && <div className="list-item-split-row-item-right me-2"><BsLockFill /></div>}
                    </div>
                    <div className="list-item-sub-item">{lastChanged}</div>
                </div>
            );
        }
        else {
            return <div >
                {label}
                {loadingAnnotationSetContents && <Spinner animation="border" size="sm" className="ms-2" />}
            </div>
        }
    }



    return (
        <div>
            <div className="list-title-and-list list-wide">

                <div className="list-title-and-buttons">
                    <div className="list-title" >
                        Annotation Set:
                    </div>
                    <div className="list-buttons">
                        {CanCreateAnnotationSet(orgsInfo.currentUser, model?.ModelId, model?.ProjectId, project?.OrganisationId) && <Button className="list-top-button" disabled={model == null || model?.AnnotationSets?.length === 0} onClick={() => setShowCopyAnnotatonSetModal(true)}>Copy</Button>}
                        {CanCreateAnnotationSet(orgsInfo.currentUser, model?.ModelId, model?.ProjectId, project?.OrganisationId) && <Button className="list-top-button" disabled={model == null} onClick={() => setShowNewAnnotatonSetModal(true)}>Add</Button>}
                        {(CanEditModelPermissions(orgsInfo.currentUser, model?.ModelId, model?.ProjectId, project?.OrganisationId) || CanCreateAnnotationSet(orgsInfo.currentUser, model?.ModelId, model?.ProjectId, project?.OrganisationId)) && <Button className="list-top-button" disabled={model == null || model?.AnnotationSets?.length === 0} onClick={() => setShowEditAnnotatonSetModal(true)}>Edit</Button>}
                        {CanCreateAnnotationSet(orgsInfo.currentUser, model?.ModelId, model?.ProjectId, project?.OrganisationId) && <Button className="list-top-button" disabled={model == null || model?.AnnotationSets?.length === 0} onClick={onDelete}>Delete</Button>}
                    </div>
                </div>
            </div>
            <div className="list-container">

                <Select
                    value={setToOption(model?.AnnotationSets?.find(as => as.AnnotationSetId === selectedAnnotationSetId))}
                    onChange={setSelected}
                    closeMenuOnSelect={true}
                    name="annotationsetselect"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select an Annotation Set..."
                    options={availableAnnotationSetOptions}
                    formatOptionLabel={formatOptionLabel}
                    components={{ SingleValue }}
                    styles={selectStyles}
                />
            </div>
            <EditAnnotationSet
                helpContext={BeaconDialogMap.AddAnnotationSetDialog} parentHelpContext={parentHelpContext}
                title="Add Annotation Set"
                orgsInfo={orgsInfo}
                isAdding={true}
                show={showNewAnnotatonSetModal}
                setShow={setShowNewAnnotatonSetModal}
                annotationSet={
                    {
                        ModelId: model?.ModelId,
                        Title: "",
                        IsLocked: false,
                        UserPermissions: model?.UserPermissions?.map(p => ({
                            AnnotationSetUserPermissionId: uuid(),
                            UserId: p.UserId,
                            Permissions: p.Permissions
                        })),
                        GroupPermissions: model?.GroupPermissions?.map(p => ({
                            AnnotationSetGroupPermissionId: uuid(),
                            GroupId: p.GroupId,
                            Permissions: p.Permissions
                        })),
                        Annotations: []
                    }}
                save={createAnnotationSet}
                user={orgsInfo?.currentUser}
                model={model}
                project={project}

            />
            {selectedAnnotationSetId &&
                <EditAnnotationSet
                    helpContext={BeaconDialogMap.EditAnnotationSetDialog} parentHelpContext={parentHelpContext}
                    title="Edit Annotation Set"
                    orgsInfo={orgsInfo}
                    isAdding={false}
                    show={showEditAnnotatonSetModal}
                    setShow={setShowEditAnnotatonSetModal}
                    annotationSet={model?.AnnotationSets?.find(an => an.AnnotationSetId === selectedAnnotationSetId)}
                    save={editAnnotationSet}
                    user={orgsInfo?.currentUser}
                    model={model}
                    project={project}
                />
            }

            <ConfirmModal
                helpContext={BeaconDialogMap.DeleteAnnotationSetDialog} parentHelpContext={parentHelpContext}
                show={showConfirmDeleteDialog}
                setShow={setShowConfirmDeleteDialog}
                onOK={onDeleteConfirmed}
                okText="Delete"
                dialogBody={"Caution! All Annotations will be Deleted!"}
                dialogTitle={"Delete: " + model?.AnnotationSets?.find(an => an.AnnotationSetId === selectedAnnotationSetId)?.Title}
                passPhrase={model?.AnnotationSets?.find(an => an.AnnotationSetId === selectedAnnotationSetId)?.Title}
                passPhrasePrompt="Enter Annotation Set title to confirm deletion:"
            />

            <CopyAnnotationSet
                helpContext={BeaconDialogMap.CopyAnnotationSetDialog} parentHelpContext={parentHelpContext}
                show={showCopyAnnotatonSetModal}
                setShow={setShowCopyAnnotatonSetModal}
                project={project}
                currentModel={model}
                currentAnnotationSet={model?.AnnotationSets?.find(as => as.AnnotationSetId === selectedAnnotationSetId)}
            />


        </div >
    );
}