import React, { useState, useRef } from "react";
import Button from 'react-bootstrap/Button'
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { CanEditLicenses } from "../App/UserPermissions";
import { availableLicenseTypeOptionsOrg, LicenseTypes } from '../App/Permissions'
import { ToastLoading, ToastError, ToastSuccess } from './ToastDisplay'
import { CreateLicense, UpdateLicense, DeleteLicense } from '../Api/License'
import { AddLicense } from "./AddLicense";
import { EditLicense } from "./EditLicense";
import { Confirm } from 'react-st-modal';
import '../css/lists.css'
import { BeaconDialogMap } from '../Components/BeaconDialogMap';

import { Logger } from 'aws-amplify';
const logger = new Logger('LicenseListOrgWide');

export function OrgLicenseList(props) {
    logger.debug("props", props);
    const { currentUser, currentOrg, orgs, parentHelpContext } = props;

    logger.debug(props);

    const [selectedLicenseId, setSelectedLicenseId] = useState(null);
    const [showAddLicense, setShowAddLicense] = useState(false);
    const [showEditLicense, setShowEditLicense] = useState(false);

    const licenseList = useRef();
    const toastId = useRef(null);

    function onAddLicenseButton() {
        setShowAddLicense(true);
    }

    function onEditLicenseButton() {
        setShowEditLicense(true);
    }

    const orgWideLicenses = currentOrg?.Licenses?.filter(l => l.Type !== LicenseTypes.ReadAccess && l.Type !== LicenseTypes.WriteAccess);
    const selectedLicense = orgWideLicenses?.find(l => l.LicenseId === selectedLicenseId);

    React.useEffect(() => {
        licenseList?.current?.resetAfterIndex(0);
    }, [currentOrg?.Licenses])

    function createLicense(license) {
        CreateLicense(license)
            .then(res => {
                ToastSuccess("License Added", toastId.current, 5000);
                setShowAddLicense(false);
            })
            .catch(err => {
                ToastError(err.message, err.cause, toastId.current);
            });
    }

    async function onDeleteLicenseButton() {
        const result = await Confirm("Are you sure you want to delete License?", "Delete License");
        if (result) {
            toastId.current = ToastLoading("Deleting License");

            DeleteLicense(selectedLicenseId)
                .then(res => {
                    ToastSuccess("License Deleted", toastId.current, 5000);
                    setSelectedLicenseId(null);
                })
                .catch(err => {
                    ToastError(err.message, err.cause, toastId.current);
                });
        }
    }

    function editLicense(license, setUiDisabled) {

        setUiDisabled(true);

        UpdateLicense(license)
            .then(res => {
                ToastSuccess("License Updated", toastId.current, 5000);
                setUiDisabled(false);
                setShowEditLicense(false);
            })
            .catch(err => {
                setUiDisabled(false);
                ToastError(err.message, err.cause, toastId.current);
            });

    }


    function itemClassName(license, selected) {
        if (selected) {
            return "list-item list-item-selected";
        }
        else if (!license.IsActive) {
            return "list-item list-item-warning";
        }
        else {
            return "list-item list-item-not-selected";
        }
    }

    function maxFileSize(license) {

        if (license?.Payload?.Items?.find(p => p.Key === "MaxFileSize"))
        {
            return (
                <div className="list-item-sub-item">{"(Max file size: " + license?.Payload?.Items?.find(p => p.Key === "MaxFileSize").Value + " MB)"}</div>
            );
        }
        else {
            return (<></>);
        }
        
    }

    const ListRowLicense = ({ index, style }) => (

        <div role="button"
            style={{
                ...style,
                backgroundColor: "#FAFAFA",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflowX: "hidden"
            }}


            className={itemClassName(orgWideLicenses[index], selectedLicenseId === orgWideLicenses[index].LicenseId)}
            onClick={() => {
                //set group and prompt re-draw
                setSelectedLicenseId(orgWideLicenses[index].LicenseId);
            }}
        >
            {orgWideLicenses[index].IsActive ?
                <div className="list-item-title">
                    <div>{orgWideLicenses[index].Title}</div>
                    {maxFileSize(orgWideLicenses[index])}
                </div>
                :
                <div className="list-item-split-row-spaced">
                    <div className="list-item-title list-item-split-row-item-left">
                        <div>{orgWideLicenses[index].Title}</div>
                    </div>
                    <div className="list-item-title list-item-split-row-item-right">INACTIVE</div>
                </div>
            }

            {(!orgWideLicenses[index].IsActive && orgWideLicenses[index].StatusText) &&
                <div className="list-item-normal-item">
                    {"Status: " + orgWideLicenses[index].StatusText}
                </div>}
        </div>
    );

    function getRowHeight(index) {
        if (orgWideLicenses[index]?.IsActive) {
            return 50;
        }
        else {
            return 60;
        }
    }

    return (
        currentUser != null ?
            <div className="list-title-and-list list-short  list-narrow">
                <div className="list-title-and-buttons">
                    <div className="list-title" >Organisation Licenses:</div>
                    <div className="list-buttons">
                        {(CanEditLicenses(currentUser)) && <Button className="list-top-button" onClick={onAddLicenseButton}>Add</Button>}
                        {(CanEditLicenses(currentUser)) && <Button disabled={selectedLicenseId === null} className="list-top-button" onClick={onEditLicenseButton}>Edit</Button>}
                        {(CanEditLicenses(currentUser)) &&
                            <Button
                                disabled={selectedLicenseId === null}
                                className="list-top-button"
                                onClick={onDeleteLicenseButton}>
                                Delete
                            </Button>
                        }
                    </div>
                </div>

                <div className="list-container">
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                ref={licenseList}
                                height={height}
                                itemCount={orgWideLicenses ? orgWideLicenses.length : 0}
                                itemSize={getRowHeight}
                                width={340}
                                className="list-background-style"
                            >
                                {ListRowLicense}
                            </List>
                        )}
                    </AutoSizer>
                </div>
                <AddLicense
                    helpContext={BeaconDialogMap.AddOrgLicenseDialog}
                    parentHelpContext={parentHelpContext}

                    isAddingOrgLicenses={true}
                    availableLicenseTypes={availableLicenseTypeOptionsOrg()}
                    show={showAddLicense}
                    setShow={setShowAddLicense}
                    createLicense={createLicense}
                    currentOrg={currentOrg} orgs={orgs}

                />
                <EditLicense
                    helpContext={BeaconDialogMap.EditLicenseDialog}
                    parentHelpContext={parentHelpContext}
                    show={showEditLicense}
                    setShow={setShowEditLicense}
                    editLicense={editLicense}
                    licenseToEdit={selectedLicense}
                />

            </div>
            :
            <></>
    );

}