import React, { useState, useRef } from 'react';
import Select from 'react-select'
import { useDispatch } from 'react-redux';
import { Logger } from 'aws-amplify';
import '../css/lists.css'
import Button from 'react-bootstrap/Button'
import { v4 as uuid } from "uuid";
import { ToastError, ToastLoading, ToastSuccess } from './ToastDisplay'
import { DeleteProject, UpdateProject, CreateProject } from '../Api/ProjectApi'
import { ConfirmModal } from './ConfirmModal'
import { EditProject } from './EditProject';
import { ModelPermissionFlags } from "../App/Permissions";
import {
    CanDeleteProject, CanUpdateProject,
    CanCreateProject, CanEditProjectPermissions
} from '../App/UserPermissions';
import Spinner from 'react-bootstrap/Spinner'

import { selectStyles } from '../App/Utils'
import { BeaconDialogMap } from '../Components/BeaconDialogMap';

const logger = new Logger('ProjectSelect');



function projectToOption(project) {
    return {
        value: project?.ProjectId,
        label: project?.Title,
        dateCreated: "Created: " + (new Date(project?.DateCreated)).toLocaleString()
    };
}

function projectsToOptions(projects) {
    return projects?.map(p => (projectToOption(p)));
}
//=======================================

export function ProjectSelect(props) {
    logger.debug(props);
    const { currentOrg, selectedProjectId, setSelectedProjectId, orgsInfo, loadingProjectContents, parentHelpContext } = props;

    const dispatch = useDispatch();

    const [showProjectAddDialog, setShowProjectAddDialog] = useState(false);
    const [showProjectEditDialog, setShowProjectEditDialog] = useState(false);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);


    const toastId = useRef();


    //load selected project from storage
    React.useEffect(() => {

        if (currentOrg?.Projects?.length) {
            //if in current list, select it
            if (!currentOrg?.Projects.find(p => p.ProjectId === selectedProjectId) !== null) {
                setSelectedProjectId(currentOrg?.Projects[0]?.ProjectId);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currentOrg?.Projects])


    function setSelected(selectedOption) {

        setSelectedProjectId(selectedOption.value)
    }


    async function onUpdateProjectTitle(project, setUiDisabled) {

        toastId.current = ToastLoading("Updating Project");
        setUiDisabled(true);
        UpdateProject(project)
            .then(res => {
                ToastSuccess("Updated [" + project.Title + "]", toastId.current, 5000);
                setUiDisabled(false);
            })
            .catch(err => {
                ToastError(err.message, err.cause, toastId.current);
                setUiDisabled(false);
            });

    }


    async function onCreateProject(project, setUiDisabled) {

        toastId.current = ToastLoading("Adding Project " + project.Title);

        setUiDisabled(true);
        CreateProject(project)
            .then(res => {
                setUiDisabled(false);
                setSelectedProjectId(res);
                ToastSuccess("Added [" + project.Title + "]", toastId.current, 5000);
                setShowProjectAddDialog(false);
            })
            .catch(err => {
                setUiDisabled(false);
                ToastError(err.message, err.cause, toastId.current);
            });

    }


    function onDeleteProject() {
        setShowConfirmDeleteDialog(true);
    }


    async function onDeleteConfirmed() {

        let projectToDelete = currentOrg.Projects.find(item => item.ProjectId === selectedProjectId);

        toastId.current = ToastLoading("Deleting Project");

        DeleteProject(selectedProjectId)
            .then(res => {
                ToastSuccess("Deleted [" + projectToDelete.Title + "]", toastId.current, 5000);
            })
            .catch(err => {
                ToastError(err.message, err.cause, toastId.current);
            });
    }


    function formatOptionLabel(option, meta) {

        const { value, label, dateCreated } = option;
        const { context } = meta;

        if (value === null || value === undefined) {
            return <div className="text-muted">No Project Selected</div>
        }
        else if (context === "menu") {
            return (
                <div role="button" className={value === selectedProjectId ? "select-list-item select-list-item-selected" : "select-list-item select-list-item-not-selected"}>
                    <div className="list-item-title">{label}</div>
                    <div className="list-item-sub-item">{dateCreated}</div>
                </div>
            );
        }
        else {
            return <div >
                {label}
                {loadingProjectContents && <Spinner animation="border" size="sm" className="ms-2" />}
            </div>
        }
    }

    function confirmationMessage() {

        const project = currentOrg?.Projects?.find(p => p.ProjectId === selectedProjectId);

        const numModels = project?.Models?.length;
        const numCrsTransforms = project?.CrsTransforms?.length;

        let numAnnotationSets = 0;
        let numAnnotations = 0;
        project?.Models.forEach(model => {
            numAnnotationSets += model?.AnnotationSets.length;
            model.AnnotationSets.forEach(annotationSet => {
                numAnnotations += annotationSet.Annotations.length;
            });
        });

        return "This will delete " + numModels + " models, " + numAnnotationSets + " annotation sets with " + numAnnotations + " annotations and " + numCrsTransforms + " CRS transforms";

    }


    return (
        <div className="me-2">
            <div className="list-title-and-list list-wide">

                <div className="list-title-and-buttons">
                    <div className="list-title" >
                        Project:
                    </div>
                    <div className="list-buttons">
                        {CanCreateProject(orgsInfo.currentUser, currentOrg?.OrganisationId) && <Button className="list-top-button" onClick={() => setShowProjectAddDialog(true)}>Add</Button>}
                        {(CanEditProjectPermissions(orgsInfo.currentUser, selectedProjectId, currentOrg?.OrganisationId) || CanUpdateProject(orgsInfo.currentUser, selectedProjectId, currentOrg?.OrganisationId)) && <Button className="list-top-button" disabled={selectedProjectId === null} onClick={() => setShowProjectEditDialog(true)}>Edit</Button>}
                        {CanDeleteProject(orgsInfo.currentUser, selectedProjectId, currentOrg?.OrganisationId) && <Button className="list-top-button" disabled={selectedProjectId === null} onClick={onDeleteProject}>Delete</Button>}
                    </div>
                </div>
            </div>
            <div className="list-container">

                <Select
                    value={projectToOption(currentOrg?.Projects?.find(p => p.ProjectId === selectedProjectId))}
                    onChange={setSelected}
                    closeMenuOnSelect={true}
                    name="annotationsetselect"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select a Project..."
                    options={projectsToOptions(currentOrg?.Projects)}
                    menuShouldScrollIntoView
                    menuPortalTarget={document.body}
                    formatOptionLabel={formatOptionLabel}
                    styles={selectStyles}
                />
            </div>
            <EditProject
                helpContext={BeaconDialogMap.AddProjectDialog} parentHelpContext={parentHelpContext}
                currentOrg={currentOrg}
                onCreateProject={onCreateProject}
                show={showProjectAddDialog}
                setShow={setShowProjectAddDialog}
                dialogTitle="Add Project"
                isAdding={true}
                currentUser={orgsInfo?.currentUser}
                project={
                    {
                        Title: "",
                        OrganisationId: currentOrg?.OrganisationId,
                        GroupPermissions: [],
                        UserPermissions: [{
                            ProjectUserPermissionId: uuid(),
                            UserId: orgsInfo?.currentUser?.UserId,
                            Permissions: ModelPermissionFlags.AllowRead | ModelPermissionFlags.AllowWrite
                        }]
                    }
                } />
            <EditProject
                helpContext={BeaconDialogMap.EditProjectDialog} parentHelpContext={parentHelpContext}
                currentOrg={currentOrg}
                orgs={orgsInfo?.organisations}
                currentUser={orgsInfo?.currentUser}
                onUpdateProjectTitle={onUpdateProjectTitle}
                show={showProjectEditDialog}
                setShow={setShowProjectEditDialog}
                dialogTitle="Edit Project"
                project={currentOrg?.Projects?.find(p => p.ProjectId === selectedProjectId)}

            />
            <ConfirmModal
                helpContext={BeaconDialogMap.DeleteProjectDialog} parentHelpContext={parentHelpContext}
                show={showConfirmDeleteDialog}
                setShow={setShowConfirmDeleteDialog}
                onOK={onDeleteConfirmed}
                okText={"Delete"}
                dialogBody={confirmationMessage()}
                dialogTitle={"Delete Project: " + currentOrg?.Projects?.find(p => p.ProjectId === selectedProjectId)?.Title}
                passPhrase={currentOrg?.Projects?.find(p => p.ProjectId === selectedProjectId)?.Title}
                passPhrasePrompt="Enter Project title to confirm deletion:"
            />

        </div >
    );
}