import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux';
import Select from 'react-select'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import { UserPermissionsInModelList, GroupPermissionsInModelList } from './PermissionList';
import { selectOrganisationsInfo } from '../App/organisationsInfoSlice';
import { ExternalUserPermissions } from './EditModelPermissions';
import '../css/annotation-properties.css'
import '../css/modal.css'
import { ToastError, ToastSuccess } from '../Components/ToastDisplay'
import { ShareModelWithExternalUser } from '../Api/Sharing'
import { BeaconDialogMap, BeaconSetSuggestions } from '../Components/BeaconDialogMap';
import { CanUpdateModel } from '../App/UserPermissions';
import { Logger } from 'aws-amplify';
const logger = new Logger('EditModel');

function getCrsTitle(crs, model) {
    if (crs.IsNative) {
        return "[Native CRS] " + model.Title;
    }
    else {
        return crs.Title;
    }
}

function CrssToOptions(crss, model) {
    return crss.map(c => ({ value: c.CrsId, label: getCrsTitle(c, model) }));
}
function StyleSheetsToOptions(styleSheets) {
    let options = styleSheets.map(ss => ({ value: ss.AnnotationStyleSheetId, label: ss.Title }));
    options.push({ value: "00000000-0000-0000-0000-000000000000", label: "None" });
    return options;
}

export function EditModel(props) {
    const { model, show, project, helpContext, parentHelpContext, user } = props;

    logger.debug("props", props);
    const toastid = useRef(null);

    const form = useRef(null);
    const orgsInfo = useSelector(selectOrganisationsInfo);

    const [title, setTitle] = useState("");
    const [defaultCrsOption, setDefaultCrsOption] = useState(null);
    const [defaultStyleSheetOption, setDefaultStyleSheetOption] = useState(null);
    const [validated, setValidated] = useState(false);
    const [availableCrssOptions, setAvailableCrssOptions] = useState(CrssToOptions(model.CRSs, model));
    const [availableStyleSheetsOptions, setAvailableStyleSheetsOptions] = useState(StyleSheetsToOptions(project.AnnotationStyleSheets));
    const [userPermissions, setUserPermissions] = useState(model.UserPermissions);
    const [groupPermissions, setGroupPermissions] = useState(model.GroupPermissions);
    const [uiDisabled, setUiDisabled] = useState(false);
    const [showAddExternalUser, setShowAddExternalUser] = useState(false);

    React.useEffect(() => {
        if (show) {
            BeaconSetSuggestions(helpContext);
        }
        else {
            BeaconSetSuggestions(parentHelpContext);
        }
    }, [helpContext, parentHelpContext, show])


    function styleSheetLabel(ssId) {
        const ss = project.AnnotationStyleSheets.find(ss => ss.AnnotationStyleSheetId === model.DefaultStyleSheetId);
        if (ss) {
            return ss.Title;
        }
        else {
            return "None";
        }
    }

    React.useEffect(() => {
        if (props.show) {

            setTitle(model.Title);
            setAvailableCrssOptions(model.CRSs ? CrssToOptions(model.CRSs, model) : null);
            setDefaultCrsOption({ value: model.DefaultCrsId, label: getCrsTitle(model.CRSs.find(c => c.CrsId === model.DefaultCrsId), model) });
            setAvailableStyleSheetsOptions(project.AnnotationStyleSheets ? StyleSheetsToOptions(project.AnnotationStyleSheets) : null);
            setDefaultStyleSheetOption({ value: model.DefaultStyleSheetId, label: styleSheetLabel(model.DefaultStyleSheetId) })
            setUserPermissions(model.UserPermissions);
            setGroupPermissions(model.GroupPermissions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [model, props.show, project])


    React.useEffect(() => {

        if (show) {
            setUserPermissions(model.UserPermissions);
        }
    }, [model.UserPermissions, show])
    React.useEffect(() => {

        if (show) {
            setGroupPermissions(model.GroupPermissions);
        }
    }, [model.GroupPermissions, show])


    function onSave(event) {
        event.preventDefault();

        if (form.current.checkValidity() && defaultCrsOption?.value) {

            //update with form values
            let newModel = JSON.parse(JSON.stringify(model));

            newModel.Title = title;
            newModel.DefaultCrsId = defaultCrsOption.value;
            newModel.DefaultStyleSheetId = defaultStyleSheetOption.value;
            props.onSave(newModel, setUiDisabled);

        }
        else {
            //failed validation
            event.stopPropagation();

        }

        setValidated(true);
    }

    function onClose() {
        props.setShow(false);
    }

    async function onAddExternalUser(permissions) {
        logger.debug("onAddExternalUser", permissions);

        try {
            await ShareModelWithExternalUser(permissions.UserName, permissions.Permissions, model.ModelId, permissions.Propagate);
            ToastSuccess("A share to this user has been added.\nYou may now select them in any user lists when setting permissions.", toastid.current, 50000);
            setShowAddExternalUser(false);
        } catch (error) {
            ToastError(error.message, error.cause, toastid.current);
        }

    }

    return (
        <Modal
            show={props.show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
            dialogClassName="modal-900w"
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.dialogTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="mx-2 my-2">
                    <Row className="border-bottom-only pb-2">
                        <Form ref={form} noValidate validated={validated} onSubmit={onSave}>
                            <Form.Group className="my-2" controlId="formModelTitle" as={Row}>
                                <Form.Label column sm={2}>Title:</Form.Label>
                                <Col sm={8}>
                                    <Form.Control required value={title} onChange={(e) => setTitle(e.target.value)} placeholder='Model Title...' 
                                        disabled={!CanUpdateModel(user, model?.ModelId, project?.OrganisationId, project?.ProjectId)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a title.
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group className="my-2" controlId="formGroupCrss" as={Row}>
                                <Form.Label column sm={2}>Default CRS:</Form.Label>
                                <Col sm={8}>
                                    <Select
                                        value={defaultCrsOption}
                                        onChange={setDefaultCrsOption}
                                        closeMenuOnSelect={true}
                                        name="groups"
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Click to Select default CRS..."
                                        options={availableCrssOptions}
                                        isDisabled={!CanUpdateModel(user, model?.ModelId, project?.OrganisationId, project?.ProjectId)}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group controlId="formGroupStyleSheet" as={Row}>
                                <Form.Label column sm={2}>Default Style Sheet:</Form.Label>
                                <Col sm={8}>
                                    <Select
                                        value={defaultStyleSheetOption}
                                        onChange={setDefaultStyleSheetOption}
                                        closeMenuOnSelect={true}
                                        name="styleSheets"
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Click to Select default Style Sheet..."
                                        options={availableStyleSheetsOptions}
                                        isDisabled={!CanUpdateModel(user, model?.ModelId, project?.OrganisationId, project?.ProjectId)}
                                    />
                                </Col>
                                <Col sm={2}>
                                    {CanUpdateModel(user, model?.ModelId, project?.OrganisationId, project?.ProjectId) && <Button disabled={uiDisabled} variant="primary" onClick={onSave}>Save</Button>}
                                </Col>
                            </Form.Group>
                        </Form>
                    </Row>
                    <Row className="mt-5">
                        <Col>
                            <UserPermissionsInModelList
                                parentHelpContext={helpContext}
                                userPermissions={userPermissions}
                                setUserPermissions={setUserPermissions}
                                canEdit={true}
                                userOrg={orgsInfo?.organisations?.find(o => o?.OrganisationId === orgsInfo?.currentUser?.OrganisationId)}
                                orgs={orgsInfo?.organisations}
                                isAdding={false}
                                model={model}
                            />
                        </Col>
                        <Col>
                            <GroupPermissionsInModelList
                                parentHelpContext={helpContext}
                                groupPermissions={groupPermissions}
                                setGroupPermissions={setGroupPermissions}
                                canEdit={true}
                                userOrg={orgsInfo?.organisations?.find(o => o?.OrganisationId === orgsInfo?.currentUser?.OrganisationId)}
                                isAdding={false}
                                orgs={orgsInfo?.organisations}
                                model={model}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button className="list-top-button" onClick={() => setShowAddExternalUser(true)}>Share with user in another organisation</Button>
                            <ExternalUserPermissions
                                helpContext={BeaconDialogMap.ShareWithAnExternalUserDialog} parentHelpContext={helpContext}
                                showpropagate={true}
                                showDialog={showAddExternalUser}
                                setShowDialog={setShowAddExternalUser}
                                onSavePermission={onAddExternalUser}
                                subItemTitle="Annotation Sets in the Model"

                            />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>Close</Button>
            </Modal.Footer>
        </Modal>

    );

}

export default EditModel;